import { getLanguageCodeFromUrl } from "@apetito/portal-sdk-common";

/* That's temporary solution to give users some feedback in case something went wrong. */
const errorMessages = new Map([
  [
    "de-DE",
    "Leider funktioniert Ihr Login derzeit nicht. Bitte probieren Sie es in fünf Minuten nochmals. Sollte das Problem dann weiterhin bestehen, wenden Sie sich bitte an unseren Web-Support.",
  ],
  [
    "nl-NL",
    "Momenteel kunt u niet inloggen. Probeer het over vijf minuten nog een keer. Als het probleem zich blijft voordoen, neem dan contact met ons op via binnendienst@apetito.nl",
  ],
  ["de-AT", ""], // We have no de-AT message - it falls back to de-DE by default.
]);
const refreshLabels = new Map([
  ["de-DE", "Erneut probieren"],
  ["nl-NL", "Probeer het opnieuw"],
  ["de-AT", ""],
]);

/**
 * When AADB2C request for a token, or our users/current call fails, we need to give some feedback to the users saying something went wrong.
 * We'll simply hide the loading animation and show language-specific message in its place.
 * */
export const displayErrorMessage = () => {
  const rootPreloaderContainer = document.getElementById("root-preloader");
  const messageContainer = rootPreloaderContainer?.querySelector(".message p");
  const refreshLink = rootPreloaderContainer?.querySelector(".message a");
  const languageCode = getLanguageCodeFromUrl();
  const message =
    errorMessages.get(languageCode) || errorMessages.get("de-DE") || null;
  const refreshLabel =
    refreshLabels.get(languageCode) || refreshLabels.get("de-DE") || "Reload";

  if (message && messageContainer) {
    rootPreloaderContainer.classList.add("errored");
    messageContainer.textContent = message;

    if (refreshLink) {
      refreshLink.textContent = refreshLabel;
    }
  }
};
