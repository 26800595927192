import {
  Action,
  APP_STATUS,
  AppStatusAction,
  authentication,
  getSingleSPALifecycleEventBus,
} from "@apetito/portal-sdk-common";
import { getActiveApplicationNames } from "./applications";

/**
 * @returns A windowed-observable event bus responsible for communicating application states on different load stages.
 * */
const getLoadingBus = () => {
  const { eventBus: sspaAppsEventBus } = getSingleSPALifecycleEventBus();

  return { sspaAppsEventBus };
};

/**
 * Function responsible for hooking SSPA event bus handler and returning its util function {checkSSPABus}
 * which determines whether all relevan apps got successfully mounted on the page.
 * */
export const hookLoadingHandler = (singleSpaApplications) => {
  const { sspaAppsEventBus } = getLoadingBus();

  /**
   * This function checks {sspaAppsEventBus} events payload in search for the apps that are mounted successfully.
   * Mounted apps need to have matching names with {apps} constant in order say whether we've finished loading given view.
   * */
  const checkSSPABus = (lastEvent?: Action<AppStatusAction>) => {
    const events = [lastEvent, ...sspaAppsEventBus.getEvents()].filter(Boolean);
    const apps = getActiveApplicationNames(singleSpaApplications);
    const authenticated = authentication.isSignedIn();

    if (
      !apps.length ||
      (authenticated &&
        apps.length === 1 &&
        apps[0].application === "@apetito/signin")
    ) {
      // There is no applications to be mounted, or we're on the signin application view.
      // In that case we wait want to show the in-built loading of signin app.
      return false;
    }

    /**
     * When we access an application that is guarded by application-guard app,
     * we have to check if any of the other apps have the status of NOT_MOUNTED
     * which would indicate the app is ready to be used, but needs some action
     * to make it past the application-guard.  */
    let hasApplicationGuardInQueue = false;

    if (apps.some((app) => app.application === "@apetito/application-guard")) {
      hasApplicationGuardInQueue = true;

      const spliceIndex = apps.findIndex(
        (app) =>
          app.status === APP_STATUS.NOT_MOUNTED &&
          app.application !== "@apetito/application-guard"
      );

      if (spliceIndex >= 0) {
        apps.splice(spliceIndex, 1);
      }
    }

    /**
     * Loop through all the apps that are perceived by SSPA to be mounted and determine if all of them have mounted successfully.
     * */
    const allRelevantAppsMounted = apps.every((app) => {
      const { application, active } = app;

      return events.some(({ payload }) => {
        const { appName, status } = payload;

        if (hasApplicationGuardInQueue) {
          /**
           * We kinda don't know which application is being guarded with application-guard,
           * so we have to skip the name check.
           * */
          return active && status === APP_STATUS.MOUNTED;
        }

        return (
          appName === application && active && status === APP_STATUS.MOUNTED
        );
      });
    });

    if (allRelevantAppsMounted) {
      // All loaded, so we're good to hide the preloader for now.
      document.body.classList.add("root-loaded");

      return true;
    }

    return false;
  };

  const handleSSPAHookEvent = (event: Action<AppStatusAction>) => {
    const isMounted = event?.payload?.status === APP_STATUS.MOUNTED;

    if (isMounted) {
      // If the app got mounted, we will want to check the states of all currently mounted (or about to be mounted) applications.
      return checkSSPABus(event);
    }

    return false;
  };

  // Hook the handler to SSPA event bus
  sspaAppsEventBus.subscribe(handleSSPAHookEvent);

  return {
    checkSSPABus,
  };
};
