import { getAppNames, getAppStatus } from "single-spa";

export const getActiveApplicationNames = (singleSpaApplications) => {
  const registeredApplicationNames = getAppNames();

  function shouldBeActive(app) {
    try {
      if (Array.isArray(app.activeWhen)) {
        return app.activeWhen.every((fn) => fn(window.location));
      }

      return app.activeWhen(window.location);
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  return registeredApplicationNames
    .map((application) => {
      const sspaApplication = singleSpaApplications.find(
        (app) => app.name === application
      );
      const status = getAppStatus(application);
      const active = shouldBeActive(sspaApplication);

      return {
        application,
        status,
        active,
      };
    })
    .filter((app) => app.active);
};
