import {
  Action,
  getUserDataEventBusInstance,
  ICurrentUserData,
  ISocketConnectedCurrentUserData,
} from "@apetito/portal-sdk-common";
import { Observable } from "windowed-observable";

export const userDataEventBus: Observable<
  Action<ISocketConnectedCurrentUserData>
> = getUserDataEventBusInstance();

export function getCurrentUsersPermissions(): string[] {
  const { payload } = userDataEventBus.getLastEvent() || {};

  return extractUserPermissionsAsStringArray(payload);
}

export function extractUserPermissionsAsStringArray(
  userData: ICurrentUserData
): string[] {
  if (userData === undefined) return undefined;

  return userData?.all?.permissions?.map((permission) => permission.name) ?? [];
}
