/**
 * Sometimes we need to determine how the navigation should be handled by SSPA depending on the URI structure.
 * For that, we want to get the full path of given URI and decide whether we need to do some redirects.
 *
 * @returns The full path of given URL object containing both {pathname} and {hash} params.
 * */
export const getFullPathFromURL = (url: URL) => {
  const { pathname, hash } = url;

  if (hash.length <= 2) {
    return pathname;
  }

  return `${pathname}${hash}`;
};

/**
 * Removes the slash (/) from the end of given {path}.
 * */
export const trimTrailingSlash = (path: string) => {
  return path.replace(/\/+$/, "");
};
