/* eslint-disable no-console */
import {
  registerApplication,
  start,
  triggerAppChange,
  unloadApplication,
} from "single-spa";
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from "single-spa-layout";

import {
  appInsights,
  authentication,
  getBearerToken,
  ISocketConnectedCurrentUserData,
  navigateToUrl,
  useCurrentUserDataLoader,
  useLocizeCache,
  usePrioritizedLanguageCode,
} from "@apetito/portal-sdk-common";
import {
  configureApplications,
  findApplicationAndEntryPointByUrl,
  isNavigationGranted,
} from "./meinApetitoApplications";
import microfrontendLayout from "./microfrontend-layout.html";
import applications, { applicationsPublicPaths } from "./config/applications";
import { userDataEventBus } from "./utils/currentUserDataLoader";
import { v4 as uuidv4 } from "uuid";
import { displayErrorMessage } from "./utils/error";
import { hookLoadingHandler } from "./utils/loading";
import { getActiveApplicationNames } from "./utils/applications";
import { ICurrentUserData } from "portal-sdk-common";
import { getFullPathFromURL, trimTrailingSlash } from "./utils/url";
import { useRedirectStorage } from "./utils/redirect";

type SSPARoutingDetails = {
  detail: {
    newAppStatuses: Record<string, unknown>;
    appsByNewStatus: {
      NOT_MOUNTED: unknown[];
      NOT_LOADED: unknown[];
      MOUNTED: unknown[];
      SKIP_BECAUSE_BROKEN: unknown[];
    };
    totalAppChanges: number;
    originalEvent: void;
    oldUrl: string;
    newUrl: string;
    navigationIsCanceled: boolean;
    cancelNavigation: () => void;
  };
};

const routes = constructRoutes(microfrontendLayout);
const singleSpaApplications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const userDataLoader = useCurrentUserDataLoader();

let currentUserData: ISocketConnectedCurrentUserData = null;

async function runLocize() {
  const applicationsToMount = getActiveApplicationNames(singleSpaApplications);
  const applicationsNames = applicationsToMount.map((app) => app.application);
  const languageCode = usePrioritizedLanguageCode(
    currentUserData?.all?.languageCodes
  );
  const locize = useLocizeCache("", languageCode, "de-DE");

  return await locize.getNamespacesTranslations(applicationsNames);
}

const SSPA_SIGNIN_PATH = "/signin";
const AUTH_ACTION_REDIRECT_PATH_PREFIX = "/signin/authentication";

const { checkSSPABus } = hookLoadingHandler(singleSpaApplications);

try {
  authentication.loadAuthModule();

  authentication.onAccountChanged(async () => {
    await triggerAppChange(); // re-evaluates the activity function
    const isSignedIn = authentication.isSignedIn();

    if (!isSignedIn) {
      return redirectUnauthenticatedOnAccountChanged();
    }

    getBearerToken()
      .then(() => {
        userDataLoader(true)
          .then(async ({ result }) => {
            await handleUserCurrentCall(result);
          })
          .catch(() => {
            displayErrorMessage();
          });
      })
      .catch((error: Error) => {
        displayErrorMessage();

        appInsights.trackEvent({
          name: "Login_Portal_Root_ApetitoRootConfig_GetBearerToken",
          properties: {
            customerNumbers: currentUserData?.customers,
            error: error,
          },
        });
      });
  });
} catch (error: unknown) {
  appInsights.trackEvent({
    name: "Login_Portal_Root_ApetitoRootConfig_OnAccountChanged",
    properties: {
      customerNumbers: currentUserData?.customers,
      error: error,
    },
  });
}

const {
  save: saveRedirect,
  get: getStoredRedirectPath,
  remove: removeStoredRedirect,
} = useRedirectStorage();

(function (history) {
  const pushState = history.pushState;
  const replaceState = history.replaceState;

  history.pushState = function () {
    // In case {} state is passed, we don't want to set it to being empty on the history object, but merge it
    const state = arguments[0];
    const otherArguments = Array.prototype.slice.call(arguments, 1);
    const stateArgument = Object.assign({}, history.state, state);
    const finalArguments = [stateArgument, ...otherArguments];

    return pushState.apply(history, finalArguments);
  };

  history.replaceState = function (state, b, location) {
    return replaceState.apply(history, arguments);
  };
})(window.history);

window.addEventListener(
  "single-spa:before-routing-event",
  (evt: Event & SSPARoutingDetails) => {
    const eventDetails = evt.detail;
    const newUrl = new URL(eventDetails.newUrl);
    const oldUrl = new URL(eventDetails.oldUrl);
    const newFullPath = getFullPathFromURL(newUrl);
    const oldFullPath = getFullPathFromURL(oldUrl);
    const authenticated = authentication.isSignedIn();

    if (shouldCancelNavigation(newFullPath)) {
      return evt.detail.cancelNavigation();
    }

    if (newFullPath.startsWith(AUTH_ACTION_REDIRECT_PATH_PREFIX)) {
      return handleRedirectFromAzure();
    }

    if (authenticated && !currentUserData) {
      awaitApplications();
    }

    if (authenticatedUserTriesToVisitSignIn(newFullPath, authenticated)) {
      return redirectAuthenticatedUser(evt);
    }

    if (!authenticated) {
      return handleUnauthenticatedNavigation(evt);
    }

    // User not logged in and tries to visit a page that requires login?
    if (navigatingFromSignInPage(evt)) {
      awaitApplications();

      if (awaitingUserData(evt)) {
        // We are waiting for the /users/current call result.
        return;
      }
    }

    if (authenticatedUserTriesToVisitSignIn(newFullPath, authenticated)) {
      const redirectUri = oldFullPath.startsWith(SSPA_SIGNIN_PATH)
        ? "/"
        : oldFullPath;

      if (redirectUri === newFullPath) {
        return;
      }

      setTimeout(() => {
        // We have to wait for the data to update before redirecting - permissions are loaded, but are not available in the current call stack yet
        navigateToUrl(redirectUri);
      }, 0);

      return;
    }

    if (newFullPath === oldFullPath) {
      return;
    }

    if (!authenticated && !newFullPath.startsWith(SSPA_SIGNIN_PATH)) {
      saveRedirect(newUrl);

      return navigateToUrl(SSPA_SIGNIN_PATH);
    }

    // Try to navigate to previously saved redirect path
    const currentlyRequestedPath = getFullPathFromURL(newUrl);
    const previouslyRequestedPath = getStoredRedirectPath();

    if (
      previouslyRequestedPath &&
      previouslyRequestedPath !== currentlyRequestedPath &&
      authenticated
    ) {
      if (currentUserData) {
        // Remove saved redirect path after we get the result of users/current call
        removeStoredRedirect();
      }

      setTimeout(() => {
        navigateToUrl(previouslyRequestedPath);
        awaitApplications();
      }, 0);

      return;
    }

    const applicationAndEntryPoint = findApplicationAndEntryPointByUrl(
      applications,
      newUrl
    );

    if (!applicationAndEntryPoint.applicationFound) {
      navigateToUrl("/");

      return;
    }

    if (!isNavigationGranted(applicationAndEntryPoint)) {
      navigateToUrl("/");
    }
  }
);

configureApplications(applications, singleSpaApplications);

const layoutEngine = constructLayoutEngine({
  routes,
  applications: singleSpaApplications,
});

singleSpaApplications.forEach(registerApplication);
layoutEngine.activate();

start({
  // needed for vue3 router to work properly (especially when using the browser back button)
  urlRerouteOnly: true,
});

/* Helper functions */

async function redirectUnauthenticatedOnAccountChanged() {
  await runLocize();
  checkSSPABus();

  return navigateToUrl(SSPA_SIGNIN_PATH);
}

async function handleUserCurrentCall(result: ICurrentUserData) {
  currentUserData = result;

  if (!currentUserData.signalRConnectionId) {
    currentUserData.signalRConnectionId = uuidv4();
  }

  userDataEventBus.publish({
    payload: currentUserData,
    type: "UserDataLoaded",
  });

  await runLocize();
  await triggerAppChange(); // re-evaluates the activity function
  // Signin is configured to not need a login, so we have to re-mount the app when user data is loaded.
  await unloadApplication("@apetito/signin");

  checkSSPABus();
}

function awaitApplications() {
  document.body.classList.remove("root-loaded");

  checkSSPABus();
}

function shouldCancelNavigation(newFullPath: string) {
  const unTrailedPath = trimTrailingSlash(newFullPath);

  return (
    unTrailedPath.startsWith(SSPA_SIGNIN_PATH) &&
    unTrailedPath !== AUTH_ACTION_REDIRECT_PATH_PREFIX &&
    unTrailedPath !== SSPA_SIGNIN_PATH
  );
}

function handleRedirectFromAzure() {
  if (currentUserData) {
    // If we have the user data already, it seems like the user tries to go back to the redirect page.
    // Let's move him back to the root page in such case.
    return window.history.pushState({}, "", "/");
  }
}

function authenticatedUserTriesToVisitSignIn(
  visitedPath: string,
  authenticated?: boolean
) {
  return (
    visitedPath.startsWith(SSPA_SIGNIN_PATH) && authenticated && currentUserData
  );
}

function redirectAuthenticatedUser(
  sspaNavigationEvent: Event & SSPARoutingDetails
) {
  const {
    referrerOrigin,
    requestedOrigin,
    previous: { path: previousPath },
    next: { path: nextPath },
  } = parsedEventDetails(sspaNavigationEvent);

  // User tries to go to signin page while being authenticated, move him back to either the old url, or the root path.
  let goBackRedirectPath = "/";

  if (
    referrerOrigin === requestedOrigin &&
    !nextPath.startsWith(SSPA_SIGNIN_PATH)
  ) {
    goBackRedirectPath = previousPath;
  }

  return window.history.pushState({}, "", goBackRedirectPath);
}

function accessedPublicPath(path: string) {
  return applicationsPublicPaths.some((publicPath) =>
    path.startsWith(publicPath)
  );
}

function handleUnauthenticatedNavigation(
  sspaNavigationEvent: Event & SSPARoutingDetails
) {
  const {
    next: { url, path },
  } = parsedEventDetails(sspaNavigationEvent);

  if (accessedPublicPath(path)) {
    return;
  }

  saveRedirect(url);

  return navigateToUrl(SSPA_SIGNIN_PATH);
}

function parsedEventDetails(sspaNavigationEvent: Event & SSPARoutingDetails) {
  const { detail } = sspaNavigationEvent;
  const { newUrl, oldUrl } = detail;
  const newUrlObject = new URL(newUrl);
  const oldUrlObject = new URL(oldUrl);

  const requestedOrigin = newUrlObject.origin;
  const referrerOrigin =
    (document.referrer && new URL(document.referrer)?.origin) || null;

  return {
    referrerOrigin,
    requestedOrigin,
    previous: {
      url: oldUrlObject,
      path: getFullPathFromURL(oldUrlObject),
    },
    next: {
      url: newUrlObject,
      path: getFullPathFromURL(newUrlObject),
    },
  };
}

function navigatingFromSignInPage(
  sspaNavigationEvent: Event & SSPARoutingDetails
) {
  const {
    next: { path: nextPath },
    previous: { path: previousPath },
  } = parsedEventDetails(sspaNavigationEvent);

  return (
    previousPath.startsWith(SSPA_SIGNIN_PATH) &&
    !nextPath.startsWith(SSPA_SIGNIN_PATH)
  );
}

function awaitingUserData(sspaNavigationEvent: Event & SSPARoutingDetails) {
  const {
    next: { path },
  } = parsedEventDetails(sspaNavigationEvent);

  return path.startsWith(AUTH_ACTION_REDIRECT_PATH_PREFIX) && !currentUserData;
}
