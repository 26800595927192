import { getFullPathFromURL } from "./url";

const SSPA_ROUTING_REDIRECT_KEY = "meinapetito__post-signin--redirect";

/**
 * A simple function to handle stored redirect URI in localStorage,
 * In case the user wanted to visit guarded page while his token got expired,
 * he'll be able to go back there after a successful login.
 * */
export const useRedirectStorage = (key = SSPA_ROUTING_REDIRECT_KEY) => {
  const save = (url: URL) => {
    const requestedPath = getFullPathFromURL(url);
    const requestedOrigin = url.origin;
    const referrerOrigin =
      (document.referrer && new URL(document.referrer)?.origin) || null;

    if (requestedOrigin === referrerOrigin) {
      // Store only the paths that correspond to the current origin.
      localStorage.setItem(key, requestedPath);
    }
  };

  const get = () => {
    return localStorage.getItem(key);
  };

  const remove = () => {
    localStorage.removeItem(key);
  };

  return {
    save,
    get,
    remove,
  };
};
